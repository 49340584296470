import React, { useState, useEffect } from "react";
import '../css/eventos.css';
// import imagen from './libro_reclamaciones.png';
import swal from 'sweetalert';
import Modal from "react-modal"
import { event } from "react-ga";
//import logoPalePrincipal from '../../../../images/Logo_720.png';
import BarcodeScanner from "../../../componentes/BarcodeScanner";
import logo1 from "../images/bodal1.png";
import logo2 from "../images/bodal2.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { IoPersonSharp, IoPhonePortrait, IoMailSharp, IoTicketSharp } from "react-icons/io5";




const BuscarEvento = () => {
    const [prueba, setPrueba] = useState('');
    const [nombre, setNombre] = useState('');
    const [direccion, setDireccion] = useState('');
    const [fecha, setFecha] = useState('');


    //=====================================================================================
    const [buscar, setBuscar] = useState("");
    const [codigoUsuarioSeleccionado, setCodigoUsuarioSeleccionado] = useState("");

    const [dataTable, setDataTable] = useState([]);

    const [horaAsistido, setHoraAsistido] = useState("");
    const [nombreAsistente, setNombreAsistente] = useState("");
    const [numeroAsistente, setNumeroAsistente] = useState("");
    const [email, setEmail] = useState("");
    const [pax, setPax] = useState("");
    const [observacion, setOservacion] = useState("");

    const [openModalConfirmar, setOpenModalConfirmar] = useState(false);
    const [openModalRegistro, setOpenModalRegistro] = useState(false);
    const [openModalSinData, setOpenModalSinData] = useState(false)
    const [openModalCodUsado, setOpenModalCodUsado] = useState(false)
    const [openModalUnicoInvitado, setOpenModalUnicoInvitado] = useState(false)
    //=====================================================================================



    const { codigo } = useParams();

    const TraerEvento = () => {
        let jsonData2 = {

            Token: "484848",
            Operacion: "1",
            Conexion:
            {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_EVENTOS_TXPK"
            },
            Parametros:
                [
                    codigo
                ]

        };

        console.log({ jsonData2 });

        try {
            fetch(`https://apis.paleconsultores.com/v2/Pale/`, {
                method: "POST",
                body: JSON.stringify(jsonData2),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    setNombre(data.Resultado[0][0].Des_Evento)
                    setFecha(data.Resultado[0][0].Fecha_Inicio)
                    setDireccion(data.Resultado[0][0].LugarEvento)
                });

        } catch (error) {
            alert('Error con el servicio, por favor actualice la página');
        }
    }

    useEffect(() => {
        TraerEvento()

    }, [])


    //============================= Modal =============================
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [codigoRecibe, setCodigoRecibe] = useState('');

    function openModal() {
        setIsOpen(true);
        let x = 0

        // if (isValidCel === true) {
        //   enviarCodigo(celular, codigo)
        // } else if (isValidEmail === true) {
        //   enviarCodigoMail(correo, codigo)
        // }

        //enviarCodigo(celular,codigo)
        //enviarCodigoMail(correo,codigo)
    }
    function afterOpenModal() { subtitle.style.color = '#f00'; }
    function closeModal() {
        // setIsOpen(false);
        // setCodigo('')
        // setCodigoRecibe('')
    }

    const [isValidCode, setIsValidCode] = useState(false)

    //=================================================================

    const [scannedCode, setScannedCode] = useState(null);

    const handleScanSuccess = (decodedText) => {
        console.log("Código escaneado:", decodedText);
        setScannedCode(decodedText);
    };

    const handleScanError = (error) => {
        console.error("Error durante el escaneo:", error);
    };




    const limpiarCampos = () => {
        setHoraAsistido("");
        setNombreAsistente("");
        setNumeroAsistente("");
        setEmail("");
        setPax("");
        setOservacion("");
    };

    const buscarData = (forzarBuscarTodo = false) => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_TXEventoBusqueda",
            },
            Parametros: [codigo, forzarBuscarTodo ? "" : buscar],
        };
        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {

                    if (data.Resultado[0]?.length === 1 && data.Resultado[0][0].Nom_Invitado !== '' && !data.Resultado[0][0].Flag_Asitio) {
                        console.log(data.Resultado[0])
                        setCodigoUsuarioSeleccionado(data.Resultado[0][0].Cod_Invitado)
                        setNombreAsistente(data.Resultado[0][0].Nom_Invitado)
                        setPax(data.Resultado[0][0].Pax)
                        setNumeroAsistente(data.Resultado[0][0].Celular)
                        setEmail(data.Resultado[0][0].Email)
                        setOservacion(data.Resultado[0][0].Obs_Invitado)
                        setOpenModalUnicoInvitado(true)
                    }

                    if (data.Resultado.length !== 0) {
                        let dataUsuario = data.Resultado[0];
                        setDataTable(dataUsuario);
                    } else {
                        setDataTable([]);
                        setOpenModalSinData(true)
                    }
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
    };

    const buscarPorCodigo = () => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_TXEventoBusqueda",
            },
            Parametros: [codigo, buscar],
        };

        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (
                        data.Resultado.length === 1 &&
                        data.Resultado[0][0].Nom_Invitado === "" &&
                        !data.Resultado[0][0].Flag_Asitio
                    ) {
                        setOpenModalRegistro(true);
                        setPax(data.Resultado[0][0].Pax)
                    }

                    if (
                        data.Resultado.length === 1 &&
                        data.Resultado[0][0].Flag_Asitio
                    ) {
                        setOpenModalCodUsado(true);
                    }


                    if (data.Resultado.length === 0) {
                        setOpenModalSinData(true)
                    }
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
    };

    const registrarAsistencia = () => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_G",
            },
            Parametros: [
                codigoUsuarioSeleccionado,
                codigo,
                nombreAsistente,
                pax ? pax.toString() : "1",
                numeroAsistente,
                email,
                "1",
                horaAsistido ? horaAsistido : obtenerFechaHoraActual(),
                observacion,
                "REYBER",
            ],
        };
        console.log(jsonData)
        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {
                    setOpenModalConfirmar(false)
                    limpiarCampos()
                    setCodigoUsuarioSeleccionado("")
                    buscarData(true)
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
    };

    const cerrarModal = () => {
        setOpenModalRegistro(false)
        setOpenModalCodUsado(false)
        setOpenModalSinData(false)
    }

    function obtenerFechaHoraActual() {
        const ahora = new Date();

        const anio = ahora.getFullYear();
        const mes = (ahora.getMonth() + 1).toString().padStart(2, "0");
        const día = ahora.getDate().toString().padStart(2, "0");
        const horas = ahora.getHours().toString().padStart(2, "0");
        const minutos = ahora.getMinutes().toString().padStart(2, "0");
        const segundos = ahora.getSeconds().toString().padStart(2, "0");

        return `${día}-${mes}-${anio} ${horas}:${minutos}:${segundos}`;
    }

    const ingresarData = (item) => {
        setHoraAsistido(item.Asistio);
        setNombreAsistente(item.Nom_Invitado);
        setNumeroAsistente(item.Celular);
        setEmail(item.Email);
        setPax(item.Pax);
        setOservacion(item.Obs_Invitado);
    };

    useEffect(() => {
        if (buscar.length == 12) {
            limpiarCampos();
            buscarPorCodigo();
        }
    }, [buscar]);

    useEffect(() => {
        buscarData();
        console.log(codigo)
    }, []);

    function fechaPrueba(fecha) {
        const dateString = fecha;
        const indexOfT = dateString.indexOf('T');
        const timeWithoutDate = dateString.substring(11, 16);
        const dateWithoutTime = dateString.substring(0, indexOfT);
        return dateWithoutTime + ' ' + timeWithoutDate
    }

    return (
        <div>
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    // style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div>
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Ingrese el código de validación</h2>
                        <button onClick={closeModal}>close</button>
                    </div>
                    <div className="DatosModal">
                        <input
                            placeholder="0000"
                            maxLength={4}
                            value={codigoRecibe}
                        // onChange={manejadorInputCodigo}
                        />

                        <a href="">No recibí el código de validación</a>

                        <button className="botonModal"
                        // onClick={validarCodigo}
                        >Validar código</button>
                    </div>
                </Modal>
            </div>

            <nav className="header__navbar_boda">
                <nav className="navContainer2">
                    <div className="cont2logo">
                        <img className=".header__navbar_boda-image" src={logo1} alt="logoPale" />
                        <h1> {nombre}</h1>
                    </div>
                    <div>
                        <img className=".header__navbar_boda-image" src={logo2} alt="logoPale" />
                    </div>
                </nav>
            </nav>

            <div className='FormBusquedaEvento'>

                <div className='DivEvento'>
                    <div className='BuscadorEvento'>
                        <input
                            className='inputPrincipal'
                            type="text"
                            value={buscar}
                            onChange={(e) => {
                                setBuscar(e.target.value);
                            }}
                            onBlur={buscarData}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    buscarData();
                                }
                            }}
                            placeholder="Buscar invitados..." />
                    </div>

                    <BarcodeScanner
                        onScanSuccess={handleScanSuccess}
                        onScanError={handleScanError}
                    />
                    {scannedCode && <p>Código escaneado: {scannedCode}</p>}
                </div>

                <div className='Body'>
                    <label className='titleRuc'>Lista de invitados </label>
                    <div className='EventosLista'>
                        <div className="table">
                            <div className="table-header">
                                <div className="table-cell-pax">Nro</div>
                                <div className="table-cell">Nombre</div>
                                <div className="table-cell">Celular</div>
                                <div className="table-cell">Correo</div>
                                <div className="table-cell-pax">Pax</div>
                                <div className="table-cell">Fecha Ingreso</div>
                                <div className="table-cell">Registrar</div>
                            </div>
                            {dataTable.map((item, index) => (
                                <div className="table-row" key={index}>
                                    <div className="table-cell-pax">{index + 1}</div>
                                    <div className="table-cell">{item.Nom_Invitado}</div>
                                    <div className="table-cell">{item.Celular}</div>
                                    <div className="table-cell">{item.Email}</div>
                                    <div className="table-cell-pax">{item.Pax}</div>
                                    <div className="table-cell">{fechaPrueba(item.Asistio)}</div>
                                    <div className="table-cell">
                                        <button
                                            className={item.Flag_Asitio === false ? "buttonHabil" : "buttonDesabil"}
                                            onClick={() => {
                                                ingresarData(item);
                                                setOpenModalConfirmar(true);
                                                setCodigoUsuarioSeleccionado(item.Cod_Invitado);
                                            }}
                                            disabled={item.Flag_Asitio}
                                        >
                                            {item.Flag_Asitio === false ? "Registrar" : "Registrado"}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* ======================== MODALS =========================== */}
                        <div>
                            <Modal
                                isOpen={openModalRegistro}
                                onRequestClose={() => setOpenModalRegistro(false)}
                                ariaHideApp={false}
                                style={{
                                    content: {
                                        width: "40%",
                                        height: "50%",
                                        margin: "auto",
                                        padding: "0",
                                        borderRadius: "0px",
                                        textAlign: "center",
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"
                                    },
                                }}
                            >
                                <div className="CabeceraModal">
                                    <h2>REGISTRAR INVITADO</h2> <br />
                                    <div>
                                        <h4>Código invitación:</h4> <p>{buscar}</p>
                                    </div>
                                </div>

                                <div className="bodyModal">

                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPersonSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>

                                        <input
                                            type="text"
                                            value={nombreAsistente}
                                            onChange={(e) => {
                                                setNombreAsistente(e.target.value);
                                            }}
                                            placeholder="Nombre"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPhonePortrait
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={numeroAsistente}
                                            onChange={(e) => {
                                                setNumeroAsistente(e.target.value);
                                            }}
                                            placeholder="Celular"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoMailSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            placeholder="Correo"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoTicketSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={pax}
                                            onChange={(e) => {
                                                setPax(e.target.value);
                                            }}
                                            placeholder="Pases"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="PieModal">
                                    <button
                                        className="btnModal"
                                        onClick={() => {
                                            setOpenModalRegistro(false);
                                            setOpenModalConfirmar(true);
                                            setCodigoUsuarioSeleccionado(buscar);
                                        }}
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalConfirmar}
                                onRequestClose={() => setOpenModalConfirmar(false)}
                                ariaHideApp={false}
                                style={{
                                    content: {
                                        width: "35%",
                                        height: "35%",
                                        margin: "auto",
                                        padding: "0",
                                        borderRadius: "0px",
                                        textAlign: "center",
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"
                                    },
                                }}
                            >
                                <div className="CabeceraModal">
                                    <h2>Confirmar Asistencia</h2>
                                    <button
                                        onClick={() => {
                                            setOpenModalRegistro(false);
                                            setOpenModalConfirmar(false);
                                            setCodigoUsuarioSeleccionado("");
                                            setBuscar("")
                                        }}
                                    >X</button>
                                </div>

                                <div className="avisoModal">
                                    <div className="avisoModalIn">
                                        <label>¿ Desea confirmar la asistencia de {nombreAsistente}? </label>
                                    </div>
                                </div>

                                <div className="PieModal">
                                    <button
                                        className="btnModalCancel"
                                        onClick={() => {
                                            setOpenModalRegistro(false);
                                            setOpenModalConfirmar(false);
                                            setCodigoUsuarioSeleccionado("");
                                            setBuscar("")
                                        }}
                                    >
                                        Cancelar
                                    </button>

                                    <button className="btnModal2" onClick={registrarAsistencia}>Confirmar</button>
                                </div>


                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalSinData}
                                onRequestClose={() => setOpenModalSinData(false)}
                                ariaHideApp={false}
                                style={{
                                    content: {
                                        width: "35%",
                                        height: "35%",
                                        margin: "auto",
                                        padding: "0",
                                        borderRadius: "0px",
                                        textAlign: "center",
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"
                                    },
                                }}
                            >

                                <div className="CabeceraModal">
                                    <h2>Datos incorrectos</h2>
                                    <button
                                        onClick={() => {
                                            setOpenModalSinData(false);
                                            setBuscar("")
                                        }}
                                    >X</button>
                                </div>
                                <div className="avisoModal">
                                    <label>Por favor, ingrese código o nombre correctos</label>
                                </div>
                                <div className="PieModal">
                                    <button
                                        className="btnModal"
                                        onClick={() => {
                                            setOpenModalSinData(false);
                                        }}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalCodUsado}
                                onRequestClose={() => setOpenModalCodUsado(false)}
                                ariaHideApp={false}
                                style={{
                                    content: {
                                        width: "35%",
                                        height: "35%",
                                        margin: "auto",
                                        padding: "0",
                                        borderRadius: "0px",
                                        textAlign: "center",
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"
                                    },
                                }}
                            >
                                <div className="CabeceraModal">
                                    <h2>Codigo {buscar}</h2>
                                    <button
                                        onClick={() => {
                                            setOpenModalCodUsado(false);
                                            setBuscar("")
                                        }}
                                    >X</button>
                                </div>
                                <div className="avisoModal">
                                    <label>Este código ya fue registrado!!</label>
                                </div>
                                <div className="PieModal">
                                    <button
                                        className="btnModal"
                                        onClick={() => {
                                            setOpenModalCodUsado(false);
                                        }}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalUnicoInvitado}
                                onRequestClose={() => setOpenModalUnicoInvitado(false)}
                                ariaHideApp={false}
                                style={{
                                    content: {
                                        width: "40%",
                                        height: "40%",
                                        margin: "auto",
                                        padding: "0",
                                        borderRadius: "0px",
                                        textAlign: "center",
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"
                                    },
                                }}
                            >
                                <div className="CabeceraModal">
                                    <h2>Confirmar Asistencia</h2>
                                    <button
                                        onClick={() => {
                                            setOpenModalUnicoInvitado(false);
                                            setBuscar("")
                                        }}
                                    >X</button>
                                </div>

                                <div className="bodyModal">
                                    <div className="avisoModalIn">
                                        <label>¿ Desea confirmar la asistencia de {nombreAsistente}? </label>
                                    </div>

                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPhonePortrait
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={numeroAsistente}
                                            onChange={(e) => {
                                                setNumeroAsistente(e.target.value);
                                            }}
                                            placeholder="Celular"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoMailSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            placeholder="Correo"
                                        />
                                    </div>
                                </div>

                                <div className="PieModal">
                                    <button className="btnModal2"
                                        onClick={() => {
                                            registrarAsistencia()
                                            setOpenModalUnicoInvitado(false);
                                        }}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </Modal>
                        </div>
                        {/* ============================================================*/}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuscarEvento;