/* eslint-disable no-alert */
/* eslint-disable no-implied-eval */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState ,Fragment} from 'react';
import Recaptcha from 'react-recaptcha';
import './FormDownload.css';
import Media from 'react-media';
import rucEmisor from '../../../images/ruc.png';
import emisionFecha from '../../../images/fechaEmision.png';
import comprobanteSerie from '../../../images/serie.png';
import numeroComprobante from '../../../images/numero.png';
import comprobanteTipo from '../../../images/tipoComprobante.png';

export default function FormDownload() {
  const [ruc, setRUC] = useState('');
  const [date, setDate] = useState('');
  const [serie, setSerie] = useState('');
  const [numero, setNumero] = useState('');
  const [tipoComprobante, setTipoComprobante] = useState('01');
  const [verified, setverified] = useState(false);
  // specifying your onload callback function
  // eslint-disable-next-line func-names
  const callback = function () {
    // eslint-disable-next-line no-console
    console.log('Done!!!!');
  };

  // specifying verify callback function
  // eslint-disable-next-line func-names
  const verifyCallback = function (response) {
    if (response) {
      setverified(true);
    }
  };
  // console.log(verifyCallback);
  let recaptchaInstance;
 
// create a reset function
    const resetRecaptcha = () => {
      recaptchaInstance.reset();  
    };

  const toInputUppercase = (e) => {
    e.target.value = (`${e.target.value}`).toUpperCase();
  };
  function ponerCeros(number, width) {
    const numberOutput = Math.abs(number); /* Valor absoluto del número */
    const { length } = number.toString(); /* Largo del número */
    const zero = '0'; /* String de cero */
    if (width <= length) {
      if (number < 0) {
        return (`-${numberOutput.toString()}`);
      }
      return numberOutput.toString();
    }
    if (number < 0) {
      return (`-${zero.repeat(width - length)}${numberOutput.toString()}`);
    }
    return ((zero.repeat(width - length)) + numberOutput.toString());
  }

  function limpiar() {
    // eslint-disable-next-line no-implied-eval
    setTimeout('location.reload(true);', 200);
  }

  function donwloadPDF() {
    if(ruc===''){
      alert('Ingrese el ruc');
      return false;
    }

    if(date===''){
      alert('Ingrese la fecha');
      return false;
    }
    if(serie===''){
      alert('Ingrese la serie');
      return false;
    }
    if(numero===''){
      alert('Ingrese el numero');
      return false;
    }
    if(tipoComprobante==''){
      alert('Ingrese el tipo');

    }

    if (verified === false) {
      alert('Por favor haga clic en el reCAPTCHA');
      return false;
    }
    const fecha = date;
    const splitDate = fecha.split('-');
    if (splitDate.count === 0) {
      return null;
    }
    const ano = splitDate[0];
    const mes = splitDate[1];
    const dia = splitDate[2];

    // const fecha2 = `${year}/${month}/${day}`;
    const tipo = 'PDF';
    let url = 'http://api.paleconsultores.com:3000/api/AArchivo/COMPROBANTES/';
    // let url = 'http://papi.hopto.org:3000/api/AArchivo/COMPROBANTES/';
    const num = ponerCeros(numero, 8);
    const archivo= `${ruc}-${tipoComprobante}-${serie}-${num}.pdf`
    url += `${ruc}/${ano}/${mes}/${dia}/${tipo}/${archivo}`;
    // eslint-disable-next-line no-console
    console.log(url);
    // eslint-disable-next-line no-return-assign
    return document.getElementById('pdf').src = url;
  }

  function donwloadXML() {
    if(ruc===''){
      alert('Ingrese el ruc');
      return false;
    }
    if(date===''){
      alert('Ingrese la fecha');
      return false;
    }
    if(serie===''){
      alert('Ingrese la serie');
      return false;
    }
    if(numero===''){
      alert('Ingrese el numero');
      return false;
    }
    if(tipoComprobante==''){
      alert('Ingrese el tipo');

    }
    if (verified === false) {
      alert('Por favor haga clic en el reCAPTCHA');
      return false;
    }
    const fecha = date;
    const splitDate = fecha.split('-');
    if (splitDate.count === 0) {
      return null;
    }
    const ano = splitDate[0];
    const mes = splitDate[1];
    const dia = splitDate[2];

    const fecha2 = `${ano}/${mes}/${dia}`;
    const TipoArchivo = 'XML';
    const num = ponerCeros(numero, 8);
    const urln = `${ruc}/${fecha2}/${TipoArchivo}/${ruc}-${tipoComprobante}-${serie}-${num}.zip`;
    //  console.log(urln);
    const encript = btoa(urln);
    let url = 'http://api.paleconsultores.com:3000/api/AArchivo/url/';
      // let url = 'http://papi.hopto.org:3000/api/AArchivo/url/';
    url += encript;
    // setTimeout('location.reload(true);', 18000);

    // eslint-disable-next-line no-return-assign
    return window.location.href = url;
  }
  function donwloadCDR() {
    if(ruc===''){
      alert('Ingrese el ruc');
      return false;
    }
    if(date===''){
      alert('Ingrese la fecha');
      return false;
    }
    if(serie===''){
      alert('Ingrese la serie');
      return false;
    }
    if(numero===''){
      alert('Ingrese el numero');
      return false;
    }
    if(tipoComprobante==''){
      alert('Ingrese el tipo');

    }
    if (verified === false) {
      alert('Por favor haga clic en el reCAPTCHA');
      return false;
    }
    const fecha = date;
    const splitDate = fecha.split('-');
    if (splitDate.count === 0) {
      return null;
    }
    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];

    const fecha2 = `${year}/${month}/${day}`;
    const TipoArchivo = 'CDR';
    const num = ponerCeros(numero, 8);
    const urln = `${ruc}/${fecha2}/${TipoArchivo}/R-${ruc}-${tipoComprobante}-${serie}-${num}.zip`;
    const encript= btoa(urln);
    let url = 'http://api.paleconsultores.com:3000/api/AArchivo/url/';
    // let url = 'http://papi.hopto.org:3000/api/AArchivo/url/';
    url += encript;
    // setTimeout('location.reload(true);', 18000);
    // eslint-disable-next-line no-return-assign
    return window.location.href = url;
  }

  function donwloadPDFMovil() {
    if(ruc===''){
      alert('Ingrese el ruc');
      return false;
    }
    if(date===''){
      alert('Ingrese la fecha');
      return false;
    }
    if(serie===''){
      alert('Ingrese la serie');
      return false;
    }
    if(numero===''){
      alert('Ingrese el numero');
      return false;
    }
    if(tipoComprobante==''){
      alert('Ingrese el tipo');

    }
    if (verified === false) {
      alert('Por favor haga clic en el reCAPTCHA');
      return false;
    }
    const fecha = date;
    const splitDate = fecha.split('-');
    if (splitDate.count === 0) {
      return null;
    }
    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];

    const fecha2 = `${year}/${month}/${day}`;
    const TipoArchivo = 'PDF';
    const num = ponerCeros(numero, 8);
    const urln = `${ruc}/${fecha2}/${TipoArchivo}/${ruc}-${tipoComprobante}-${serie}-${num}.pdf`;
    const encript = btoa(urln);
    let url = 'http://api.paleconsultores.com:3000/api/AArchivo/url/';
    // let url = 'https://apis.paleconsultores.com/v2/Comprobantes/url/';
    url += encript;
    return window.location.href = url;
  }

  return (
    <div className="FormDownload">
      <h1>Descarga tu Comprobante</h1>
          <form>
              <div>
                  <div>
                  <div class="popover__wrapper">
                      <a href="#">
                        <label class="popover__title">RUC EMISOR:</label>
                      </a>
                      <div class="popover__content">
                      <img alt="ruc emisor" src={rucEmisor}/>
                      </div>
                  </div>
                  </div>

                {/* <label> RUC EMISOR :</label> */}

                <div>
                  <input type="number" id="ruc" placeholder="Ingrese su Ruc" minLength={11} maxLength={11}  min="1" max="99999999999" value={ruc} onChange={(e) => setRUC(e.target.value)} required />
                </div>


              </div>
              <div>

                <div>
                    <div class="popover__wrapper">
                        <a href="#">
                          <label class="popover__title">FECHA DE EMISIÓN:</label>
                        </a>
                        <div class="popover__content">
                        <img alt="ruc emisor" src={emisionFecha}  height="236px" width="619px"/>
                        </div>
                    </div>
                </div>
                {/* <label> FECHA DE EMISIÓN:</label> */}
                <div>
                  <input type="date" id="date" value={date} onChange={(e) => setDate(e.target.value)} required />
                </div>
              </div>
              <div>
                <div>
                    <div class="popover__wrapper">
                        <a href="#">
                          <label class="popover__title">TIPO DE COMPROBANTE:</label>
                        </a>
                        <div class="popover__content">
                        <img alt="ruc emisor" src={comprobanteTipo}/>
                        </div>
                    </div>
                </div>
                {/* <label> TIPO DE COMPROBANTE: </label> */}
                <div>
                  <select id="tipoComprobante" value={tipoComprobante} onChange={(e) => setTipoComprobante(e.target.value)} required>
                    <option value="01">FACTURA ELECTRONICA</option>
                    <option value="03">BOLETA DE VENTA ELECTRONICA</option>
                    <option value="07">NOTA DE CREDITO ELECTRONICA</option>
                    <option value="08">NOTA DE DEBITO ELETRONICA</option>
                    <option value="09">GUIA DE REMISION ELECTRONICA</option>

                  </select>
                </div>
              </div>
              <div className="FormDownload__input">
                <div>
                  <div>
                      <div class="popover__wrapper">
                          <a href="#">
                            <label class="popover__title">SERIE:</label>
                          </a>
                          <div class="popover__content">
                          <img alt="ruc emisor" src={comprobanteSerie}/>
                          </div>
                      </div>
                  </div>
                  {/* <label>SERIE:</label> */}
                  <div>
                    <input type="text" id="serie" placeholder="Ingrese el serie" value={serie} onChange={(e) => setSerie(e.target.value)} minLength="4" maxLength="4" onInput={toInputUppercase} required />
                  </div>
                </div>
                <div>
                  {/* <label>NUMERO:</label> */}
                    <div>
                        <div class="popover__wrapper">
                            <a href="#">
                              <label class="popover__title">NUMERO:</label>
                            </a>
                            <div class="popover__content">
                            <img alt="ruc emisor" src={numeroComprobante}/>
                            </div>
                        </div>
                    </div>
                  <div>
                    <input type="number" placeholder="Ingrese el numero" id="numero" value={numero} onChange={(e) => setNumero(e.target.value)} maxLength="8" required />
                  </div>
                </div>
              </div>

            </form>

            <div>
              <div>
                  <Recaptcha
                    sitekey="6Lf9pI8hAAAAAC4Xhh7fOKE4UEvPVAAJk4SdAFmn" // //6LdoWcwUAAAAAKWP05JQXJxuJytpWBJ8iA2i-LoO --ifacturacion ///6LdkQ8QUAAAAABmAgPUYqkH0y1msWWOl81AJzh-O ---consultas
                    onloadCallback={callback}
                    ref={e => recaptchaInstance = e}
                    verifyCallback={verifyCallback}
                    render="explicit"
                    elementID="g-recaptcha"
                    hl='es'
                  />
              </div>
            
              <div id="modal">
                <div className="modal-content">
                  <div className="copy">
                    {ruc.length===0 || date.length===0 || tipoComprobante.length===0 || serie.length===0 || numero.length===0 || verified===false ? <h3>Complete los campos solicitados por favor <a href="#" onBlur={resetRecaptcha}>Cerrar</a></h3> 
                    : ruc.length >1? <div>
                      <embed id="pdf" src="#" type="application/pdf" width="100%" height="600px"/> 
                      <div id="modal" />
                      <div> 
                        {/* <a href="#" onBlur={() => limpiar()}>Cerrar</a> */}
                        <a href="#" onBlur={resetRecaptcha}>Cerrar</a>
                        <a onClick={() => donwloadXML()}>Descargar XML</a>
                        <a onClick={() => donwloadCDR()}>Descargar CDR</a>
                      </div>
                        </div>:null}
                        {/* <embed id="pdf" src="#" type="application/pdf" width="100%" height="600px" />
                        <div id="modal" />
                        <a href="#" onBlur={() => limpiar()}>Cerrar</a>
                        <a onClick={() => donwloadXML()}>Descargar XML</a>
                        <a onClick={() => donwloadCDR()}>Descargar PDF</a> */}
                  </div>
                </div>
                <div className="overlay" />
              </div>
            </div>

            <div className="FormDownload__comprobantes">
                <Media queries={{
                  small: "(max-width: 599px)",
                  medium: "(min-width: 600px) and (max-width: 900px)",
                  large: "(min-width: 901px)"
                }}>
                  {matches => (
                    <Fragment>
                      {matches.small &&  <button className="FormDownload__buttonMovil" onClick={() => donwloadPDFMovil()} onBlur={resetRecaptcha}>DESCARGAR PDF</button>}
                      {matches.medium && <button className="FormDownload__buttonMovil" onClick={() => donwloadPDFMovil()} onBlur={resetRecaptcha}>DESCARGAR PDF</button>}
                      {matches.large && <button className="FormDownload__buttonMovil" onClick={() => donwloadPDFMovil()} onBlur={resetRecaptcha}>DESCARGAR PDF</button>}
                      {/* {matches.large && <a className="buttonPDF_a" href="#modal" onClick={() => donwloadPDF()}>VISUALIZAR PDF</a>} */}
                    </Fragment>
                  )}
                </Media>     
              <button className="FormDownload__button" onClick={() => donwloadXML()} onBlur={resetRecaptcha}>DESCARGAR XML</button>
              <button className="FormDownload__button" onClick={() => donwloadCDR()} onBlur={resetRecaptcha} >DESCARGAR CDR</button>
              {/* <div>
                <a  className="buttonPDF_a" href="#modal" onClick={() => donwloadPDF()}>DESCARGAR PDF</a>
              </div>
              <button onClick={() => donwloadXML()} onBlur={resetRecaptcha}>DESCARGAR XML</button>
              <button onClick={() => donwloadCDR()} onBlur={resetRecaptcha} >DESCARGAR CDR</button> */}
            </div>

        <div>
          <p>Pasos para descargar comprobante</p>
          <div className="video">
          <iframe  src="https://www.youtube.com/embed/PJOmrhg4Ass" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
    </div>
    );
}
